.heading {
  &-1 {
    font-size: 32px;
    font-weight: 700;
  }

  &-2 {
    font-size: 18px;
    font-weight: bold;
  }
}

.subheading {}

.text {}
