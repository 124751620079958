.m-checkbox {
  display: flex;
  align-items: center;
  padding: .5rem;

  &__input {
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none; // TODO: manage :focus
    background: $lightGrey;
    cursor: pointer;
    border: 2px solid $lightGrey;

    &::before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      transition: all .1s;
      background: $lightGrey;
    }

    &:checked {
      &::before {
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
      }
    }
  }

  &--switch {
    &__input {
      width: (2 * 20px) - (2 * 2px);
      height: 20px;
      border: 2px solid $dark;
      border-radius: 20px - (2*2px);
      background: $lightGrey;
      &::before {
        top: 2px;
        right: 20px - 2px;
        bottom: 2px;
        left: 2px;
        border-radius: 50%;
        background: $dark;
      }

      &:checked {
        border-color: $lightGrey;
        background: $dark;
        &::before {
          right: 2px;
          left: 20px - 2px;
          background: $lightGrey;
        }
      }
    }
  }
}
