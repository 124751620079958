.map-wrap {
  width: 100%;
  height: 354px;
  @include theme-aware("background-color", "map-background");

  > img {
    width: 100%;
    height: 349px;
    object-fit: cover;
  }

  svg path {
    outline: none;
    @include theme-aware("fill", "map-path-fill");
    @include theme-aware("stroke", "map-path-stroke");
  }
  .mapTitle {
    &-icons {
      svg {
        width: 15px;
        height: 15px;
        margin: 29px 0 0 0;
        stroke: none !important;
        path {
          stroke: none;
          @include theme-aware("fill", "q-icon");
        }
      }
    }
  }
  .bottom-text-wrap {
    position: absolute;
    bottom: 15px;
    left: 25px;
  }
}
.map-wrap:before {
  content: "";
  position: absolute;
  @include theme-aware("background", "map-decoration");
  top: 0;
  left: 0;
  width: 7px;
  height: 354px;
}

.map-wrap:after {
  content: "";
  position: absolute;
  @include theme-aware("background", "map-decoration");
  top: 0;
  right: 0;
  width: 7px;
  height: 354px;
  z-index: 110;
}

@include media-breakpoint-down(md) {
  .map-wrap:before {
    left: 15px;
  }

  .map-wrap:after {
    right: 15px;
  }
}
.world-wrap {
  overflow: hidden;
  position: relative;
  height: 354px;
  z-index: 10;
}
.world {
  width: 100%;
  z-index: 100;
  opacity: 0.4;
}
