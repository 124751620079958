.mapTitle {
  &-wrap {
    display: flex;
    align-items: flex-start;
    position: absolute;
  }

  &-text {
    font-family: $code-pro-font-family;
    white-space: pre;
    @include theme-aware('color', 'map-title');
    font-size: 26px;
    margin: 20px 5px 10px 35px;
    text-transform: uppercase;
  }

  &-text:after {
    content: "--";
    display: block;
    position: absolute;
    top: 45px;
  }
}

@include media-breakpoint-down(xs) {
  .mapTitle {
    &-text {
      font-size: 32px;
      margin: 20px 5px 10px 35px;
    }
  }
}
