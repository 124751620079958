.dataTitle-wrap {
  @include theme-aware('background-color', 'tile-bg');
  padding: 10px;
  margin-bottom: 9px;
  min-height: 128px;
}

.chart-block {
  height: 160px;
  width: 100%;
}
