.tileTitle {
  &-wrap {
    display: flex;
    align-items: flex-start;
  }

  &-text {
    white-space: pre;
    @include theme-aware('color', 'tile-title-color');
    font-family: $code-pro-font-family;
    font-size: 14px;
    width: 100%;
    margin: 8px 10px 10px 8px;
    border-bottom: 2px solid $tile_title_bottom_border;

    .link {
      @include theme-aware('color', 'tile-title-color');
    }

    .link:hover {
      text-decoration: underline;
    }
  }

  &-icons {
    .icon-tooltip {
      @include theme-aware('fill', 'q-icon');
    }

    .icon-tile {
      @include theme-aware('fill', 'icon-tile');
    }

    margin-top: 5px;
    display: flex;
    width: 20%;
    flex-direction: row-reverse;
    align-items: flex-start;

    .icon-tile {
      width: 30px;
    }

    .icon-tooltip {
      width: 15px;
      margin-left: 4px;
    }
  }
  
  &-tooltip {
    padding: 9px 10px 15px 20px !important;
    background: $lightGrey !important;
    border: 1px solid $dark !important;
    border-radius: 0px !important;
    font-family: $code-pro-font-family !important;
    color: $dark !important;
    text-transform: lowercase !important;

    & .active-tooltip-icon {
      width: 15px;
      position: absolute;
      left: 2px;
      top: 2px;
      fill: $q_icon_bg;
    }
  }
}

.short-letter-spacing {
  letter-spacing: -1px;
}

@include media-breakpoint-up(xl) {
  .tileTitle-icons {
    .icon-tile {
      width: 25px;
    }

    .icon-tooltip {
      width: 15px;
      margin-left: 4px;
    }
  }
}

@include media-breakpoint-down(md) {
  .tileTitle {
    &-text {
      font-size: 18px;
    }
  }
}
