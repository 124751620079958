.logo {
  margin-right: 20px;

  &-wrap {
    display: flex;
    align-items: center;
    height: 100%;

    svg {
      width: 100%;
      margin-top: 7px;
      @include theme-aware('fill', 'logoTextColor');
    }
  }

  &-text {
    font-size: 26px;
    @include theme-aware('color', 'logoTextColor');
  }
}

@media (min-width: 320px) and (max-width: 1150px) {
  .logo {
    &-wrap {
      svg {
        width: 130px;
      }
    }
  }
}