main {
  margin: 0;
}

.site-wrapper {
  margin: 0 60px;
  padding-top: 20px;
  height: 100%;
}

.corner-box {
  width: 100%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  @include theme-aware('border-color', 'corner-box-border');
  z-index: 1;
  padding: 4px;
}

.corner-box:before,
.corner-box:after {
  content: "";
  z-index: -1;
  display: block;
  position: absolute;
  @include theme-aware('background-color', 'color-background');
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.corner-box:before {
  width: calc(100% + 8px);
  height: calc(100% - 12px);
  z-index: 1;
}
.corner-box:after {
  height: calc(100% + 7px);
  width: calc(100% - 12px);
  z-index: 1;
}

.rightLine {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  min-height: 100%;

  > img {
    height: 100%;
  }
}

@include media-breakpoint-down(lg) {
  .rightLine {
    display: none;
  }
}
@include media-breakpoint-down(xs) {
  main {
    margin: 0 10px 0 10px;
  }

  .rightLine {
    display: none;
  }

  .site-wrapper {
    margin: 0 10px 0;
    height: 100%;
    padding-top: 0;
  }

  .corner-box:after {
    height: calc(100% + 10px);
    width: calc(100% - 10px);
    z-index: 1;
  }
}


@include c-media-breakpoint-1200-1360() {
  main {
    margin: 0 50px 0 20px;
  }
}

@include c-media-breakpoint-up-1360() {
  main {
    margin: 0 160px 0 75px;
  }
}



