.rangeSlider-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 25px 0 0 15px;
}

.renderTrack {
  position: relative;
  height: 25px;
  display: flex;
  width: 100%;
  border-right: 2px solid $tile_title_bottom_border;
  border-left: 2px solid $tile_title_bottom_border;

  &.renderTrackBottom {
    border-left-color: transparent;
  }

  .chartLabel {
    position: absolute;
    font-size: 10px;
    bottom: 23px;
    color: $tile_title;

    &.zero {
      left: -4px;
      @include theme-aware('color', 'range-mark');
    }

    &.hundred {
      right: -9px;
      @include theme-aware('color', 'range-mark');
    }
  }

  .chartDash {
    position: absolute;
    top: 8px;
    height: 10px;
    width: 2px;
    display: flex;
    background-color: $tile_title_bottom_border;
    bottom: 26px;

    &.first {
      left: 25%;
    }

    &.second {
      left: 50%;
    }

    &.third {
      left: 75%;
    }
  }
}

.renderThumb {
  height: 10px;
  width: 3px;
  z-index: 1 !important;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;

  &.renderThumbBottom {
    height: 25px;
  }

  &-value {
    position: absolute;
    top: -28px;
    color: #65B042;
    font-family: $code-pro-font-family;
    font-weight: bold;
    font-size: 20px;
    line-height: 18px;
    padding: 4px;
  }
}
